.appointment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

form {
    margin-bottom: 20px;
}

input[type="datetime-local"], 
input[type="number"], 
button {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

button {
    cursor: pointer;
    background-color: #5cb85c;
    color: white;
}

button:hover {
    background-color: #4cae4c;
}

.appointment-table {
    width: 100%;
    border-collapse: collapse;
}

.appointment-table th, 
.appointment-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.appointment-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.appointment-table th {
    background-color: #4CAF50;
    color: white;
}
