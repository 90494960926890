.animal-list {
    margin: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.animal-list h2 {
    color: #333;
    margin-bottom: 15px;
}

.animal-list ul {
    list-style-type: none;
    padding: 0;
}

.animal-list li {
    padding: 8px 10px;
    border-bottom: 1px solid #eee;
    color: #555;
}

.animal-list li:last-child {
    border-bottom: none;
}

.animal-list li:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}
